<template>
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <div class="flex-box">
        <vertical-menu
          class="left-menu-box"
          title
          :menuData="menuData"
          @handleMenuChange="handleMenuClick"
          :defaultCode="code"
        ></vertical-menu>
        <div class="right-list-box">
          <article-list :data="dataList" @handleClick="handleArticleClick"></article-list>
          <div class="footer">
            <div class="left">共 {{total}} 条</div>
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="page.pageNum"
              layout="prev, pager, next"
              :page-size="7"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import articleList from '@/components/articleList.vue'
import verticalMenu from '@/components/verticalMenu.vue'
export default {
  name: 'Notice',
  components: {
    articleList, verticalMenu
  },
  data() {
    return {
      page: {
        pageNum: 1,
        pageSize: 7
      },
      queryInfo: {
        keywords: null,
        type: 1
      },
      menuData: [
        {
          'name': '通知公告',
          'code': 1
        },
        {
          'name': '政策文件',
          'code': 2
        }
      ],
      dataList: [],
      total: null,
      code: null
    }
  },
  created() {
    this.code = this.$route.query.notice || 1
    this.queryInfo.type = this.code
    // 获取列表
    this.getList();
  },
  mounted() {

  },
  methods: {
    handleArticleClick(item) {
      this.$router.push({ path: '/sharedInstrument/notice/detail', query: { id: item.id } })
    },
    getList() {
      let params = Object.assign(this.page, this.queryInfo)
      this.$api.share.announcement(params).then((res) => {
        let data = res.data;
        // data.rows.forEach(item => {
        //   if (item.createTime && item.createTime.indexOf(" ") > -1) {
        //     let str = item.createTime;
        //     let arr = str.split(" ");
        //     item.time = arr[0] || ""
        //   }
        // });
        this.dataList = data.rows
        this.total = data.total
      })
    },
    handleMenuClick(val) {
      this.queryInfo.type = val.code;
      this.getList();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    }
  }
}
</script>
<style scoped lang="less">
.flex-box {
  padding: 40px 0;
}
</style>
